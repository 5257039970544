import React from "react"
import PropTypes from "prop-types"
import styles from "./LargeBuildingPage.module.scss"

const LargeBuildingPage = () => (
  <div className={styles.LargeBuildingPage} data-testid="LargeBuildingPage">
    {window.location.replace(process.env.REACT_APP_LARGE_BUILDING_PAGE_URL)}
  </div>
)

LargeBuildingPage.propTypes = {}

LargeBuildingPage.defaultProps = {}

export default LargeBuildingPage
