import React from "react"
import PropTypes from "prop-types"
import styles from "./ReportHeaderSection.module.scss"

import CTAButton from "components/CTAButton"
import BPLogo from "images/bp-logo-color.svg"

const ReportHeaderSection = () => (
  <div className={styles.ReportHeaderSection} data-testid="ReportHeaderSection">
    <img className={styles.Logo} src={BPLogo} alt="BlocPower Logo" />
    <CTAButton className={styles.CTA}>Contact Us</CTAButton>
  </div>
)

ReportHeaderSection.propTypes = {}

ReportHeaderSection.defaultProps = {}

export default ReportHeaderSection
