import React from "react"
import PropTypes from "prop-types"
import styles from "./DIYSteps.module.scss"

const DIYSteps = () => (
  <div className={styles.DIYSteps} data-testid="DIYSteps">
    <ul className={styles.CheckBoxBullet}>
      <li>
        <strong>Hire &amp; Manage:</strong>
        <ul>
          <li>Energy Engineer</li>
          <li>Auditor</li>
          <li>Construction Manager</li>
          <li>Electrician</li>
          <li>HVAC Contractor</li>
          <li>Maintenance Services</li>
        </ul>
      </li>
      <li>
        <strong>Confirm and manage project scope and budget</strong>
      </li>
      <li>
        <strong>Research and apply for incentives</strong>
      </li>
      <li>
        <strong>Secure construction permits</strong>
      </li>
      <li>
        <strong>Arrange financing with a lender</strong>
      </li>
    </ul>
  </div>
)

DIYSteps.propTypes = {}

DIYSteps.defaultProps = {}

export default DIYSteps
