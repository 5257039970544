import React from "react"
import PropTypes from "prop-types"
import styles from "./TestimonialsSection.module.scss"

import CTAButton from "components/CTAButton"
import Stan from "./stan.jpg"

const TestimonialsSection = () => (
  <div className={styles.TestimonialsSection} data-testid="TestimonialsSection">
    <div className={styles.SectionInner}>
      <img alt="Stan" src={Stan} className={styles.Image} />
      <div className={styles.Content}>
        <h2>Building upgrades can be tough. We want to help.</h2>
        <blockquote className={styles.Quote}>
          {`"Working with BlocPower is worth its weight in gold. You don't know what
        you don't know about this kind of project, but BlocPower does."`}
        </blockquote>
        <div className={styles.Citation}>
          Stan, Building Owner from Brooklyn
        </div>
        <CTAButton>Learn More</CTAButton>
      </div>
    </div>
  </div>
)

TestimonialsSection.propTypes = {}

TestimonialsSection.defaultProps = {}

export default TestimonialsSection
