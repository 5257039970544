import React, { useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import styles from "./BuildingInfoPage.module.scss"
import BuildingForm from "components/BuildingForm"
import { submitBuildingInfo } from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import FullBleedHeader from "components/FullBleedHeader"

const BuildingInfoPage = () => {
  const {
    isError,
    data = {},
    status,
  } = useSelector(state => state.buildingInfo)
  const {
    is_qualified_lead: isQualifiedLead,
    is_requalify_eligible: isRequalifyEligible,
    heating_fuel_source: heatingFuelSource,
    heating_system_age: heatingSysAge,
    message,
  } = data
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const { buildingId } = useParams()

  const onSubmit = useCallback(
    data => {
      if (data.heating_system_type == "NO_HEATING_SYS") {
        data["heating_fuel_source"] = "NO_HEATING_SYS"
        data["heating_system_age"] = "NO_HEATING_SYS"
      }
      dispatch(submitBuildingInfo(buildingId, data))
    },
    [dispatch, buildingId]
  )

  const goToReport = id => {
    navigate("../../report/" + id, { replace: true })
  }
  const goToSubscriber = () => {
    navigate("../../subscriber", { replace: true })
  }
  const goToWaitlist = () => {
    navigate("../../waitlist", { replace: true })
  }
  const goToRequalify = id => {
    navigate("../../requalify/" + id, { replace: true })
  }
  const goToLargeBuilding = () => {
    navigate("../../largebuilding/", { replace: true })
  }

  useEffect(() => {
    if (isError) {
      window.alert(message)
    } else if (status == "succeeded") {
      if (isQualifiedLead) {
        data?.no_of_units > 10 ? goToLargeBuilding() : goToReport(buildingId)
      } else if (isRequalifyEligible) {
        goToRequalify(buildingId)
      } else {
        if (
          heatingFuelSource !== "NATURAL_GAS" &&
          ["OLD", "PRETTY_OLD"].includes(heatingSysAge)
        ) {
          goToWaitlist()
        } else {
          goToSubscriber()
        }
      }
    }
  }, [isError, status, isQualifiedLead, isRequalifyEligible])

  return (
    <div>
      <FullBleedHeader />
      <div className={styles.BuildingInfoPage} data-testid="BuildingInfoPage">
        <BuildingForm onSubmit={onSubmit} />
      </div>
    </div>
  )
}

BuildingInfoPage.propTypes = {}

BuildingInfoPage.defaultProps = {}

export default BuildingInfoPage
