import React from "react"
import PropTypes from "prop-types"
import styles from "./ImplementationOption.module.scss"

const ImplementationOption = ({ title, children }) => (
  <div
    className={styles.ImplementationOption}
    data-testid="ImplementationOption"
  >
    <div className={styles.Title}>{title}</div>
    {children}
  </div>
)

ImplementationOption.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

ImplementationOption.defaultProps = {}

export default ImplementationOption
