import React from "react"
import PropTypes from "prop-types"
import styles from "./LabeledMetric.module.scss"

import MetricType from "../../constants/proptypes/Metric"

const LabeledMetric = ({ metric, comp }) => {
  const { label, value, unit } = metric
  const wrapperStyle = styles[`LabeledMetric${comp}`]
  return (
    <div className={wrapperStyle} data-testid="LabeledMetric">
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.table}>
        <div className={styles.value}>{value}</div>
        <div className={styles.unit}>{unit}</div>
      </div>
    </div>
  )
}

LabeledMetric.propTypes = {
  metric: MetricType.isRequired,
  comp: PropTypes.string,
}

LabeledMetric.defaultProps = {}

export default LabeledMetric
