import React from "react"
import PropTypes from "prop-types"
import styles from "./Button.module.scss"

const Button = ({ onClick, children }) => (
  <button className={styles.Button} data-testid="Button" onClick={onClick}>
    {children}
  </button>
)

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
}

Button.defaultProps = {}

export default Button
