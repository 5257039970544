import React, { useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import styles from "./StartPage.module.scss"
import { useDispatch, useSelector } from "react-redux"
import TextTransition, { presets } from "react-text-transition"
import AddressCollectionWidget from "components/AddressCollectionWidget"
import { submitAddress } from "../../redux/actions"
import { useNavigate, useSearchParams } from "react-router-dom"
import FullBleedHeader from "components/FullBleedHeader"
import LoadingScreen from "components/LoadingScreen"

const StartPage = () => {
  const [searchParams] = useSearchParams()
  const address = searchParams.get("address")
  const {
    isError,
    data = {},
    status,
  } = useSelector(state => state.addressSubmission)
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const onSubmit = useCallback(
    data => dispatch(submitAddress(data)),
    [dispatch]
  )
  useEffect(() => {
    if (address) {
      onSubmit({ address: address })
    }
  }, [address, onSubmit])
  const { message } = data
  useEffect(() => {
    if (isError) {
      window.alert(message)
    } else if (status == "succeeded") {
      navigate("../verify/" + data.data.user_submitted_building_id, {
        replace: true,
      })
    }
  }, [isError, status])
  const TEXTS = [
    "upgrade your building's heating system",
    "save on your utility bills",
    "increase your property value",
    "improve indoor comfort",
    "green your building",
    "retain your tenants",
    "improve health with indoor air quality",
    "improve resilience to heatwaves",
  ]
  const [index, setIndex] = React.useState(0)
  useEffect(() => {
    const intervalId = setInterval(() => setIndex(index => index + 1), 3000)
    return () => clearTimeout(intervalId)
  }, [])
  if (address) {
    return <LoadingScreen />
  }
  return (
    <div>
      <FullBleedHeader />
      <div className={styles.Page}>
        <div className={styles.Heading}>Own a building?</div>
        <div className={styles.SubHeading}>
          Learn how BlocPower can help you
        </div>
        <div className={styles.RotateText}>
          <TextTransition
            text={TEXTS[index % TEXTS.length]}
            springConfig={presets.wobbly}
          />
        </div>
        <div className={styles.TextboxTitle}>
          Get your free project recommendation and saving estimate.
        </div>
        <div className={styles.StartPage} data-testid="StartPage">
          <AddressCollectionWidget onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  )
}

StartPage.propTypes = {}

StartPage.defaultProps = {}

export default StartPage
