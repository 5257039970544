import React from "react"
import PropTypes from "prop-types"

const EmailSubForm = ({ register, errors, styles }) => {
  return (
    <fieldset data-testid="EmailSubForm">
      {/* email */}
      <div className={styles.InputGroup}>
        <label htmlFor="user_email">Email</label>
        <input
          id="user_email"
          type="email"
          {...register("user_email", { required: true })}
        />
      </div>
      {errors?.user_email?.type === "required" && (
        <span className={styles.isRequired}> Email is required. </span>
      )}
    </fieldset>
  )
}

EmailSubForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  styles: PropTypes.object,
}

EmailSubForm.defaultProps = {
  errors: {},
  styles: {},
}

export default EmailSubForm
