import React from "react"
import PropTypes from "prop-types"
import styles from "./ReportSection.module.scss"

import LabeledMetric from "components/LabeledMetric"
import MetricType from "constants/proptypes/Metric"
import CTAButton from "components/CTAButton"
import TooltipInfoIcon from "components/TooltipInfoIcon"
import {
  ElectricityIcon,
  FinancialReturnIcon,
  HealthIcon,
  LedLightIcon,
  RoomsIcon,
  SeasonsIcon,
  SolarIcon,
  WindowsIcon,
} from "components/Icons"

const ErrorMessage =
  "While your building would save energy, cost savings are unlikely at this time. Due to current natural gas costs in your area, switching to all-electric heating is likely to increase your energy bill. However, as electricity costs fall and natural gas prices rise in the future, we expect an all-electric system to save you money in the long run. In the meantime, other energy efficiency upgrades recommended above would offer small cost savings."

const ReportSection = ({ metrics }) => (
  <div className={styles.ReportSection} data-testid="ReportSection">
    <div className={styles.FloatingCells}>
      <div className={styles.ReportSection_InfoBlock}>
        <div className={styles.ReportSection_InfoBlock_label}>
          Recommendations for building upgrades
        </div>
        <div className={styles.ReportSection_InfoBlock_blockcontent}>
          <ul className={styles.List}>
            <li className={styles.ListItem}>
              <ElectricityIcon className={styles.Icon} />
              <div className={styles.ListItemContent}>
                Update heating and water heater to all-electric system
              </div>
            </li>
            <li className={styles.ListItem}>
              <WindowsIcon className={styles.Icon} />
              <div className={styles.ListItemContent}>
                Weather-strip exterior windows
              </div>
            </li>
            <li className={styles.ListItem}>
              <SolarIcon className={styles.Icon} />
              <div className={styles.ListItemContent}>
                Subscribe to community solar
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.ReportSection_InfoBlock}>
        <div className={styles.ReportSection_InfoBlock_label}>
          Additional Benefits
        </div>
        <div className={styles.ReportSection_InfoBlock_blockcontent}>
          <ul className={styles.List}>
            <li className={styles.ListItem}>
              <SeasonsIcon className={styles.Icon} />
              <div className={styles.ListItemContent}>
                All season indoor comfort
              </div>
            </li>
            <li className={styles.ListItem}>
              <RoomsIcon className={styles.Icon} />
              <div className={styles.ListItemContent}>
                Individual room unit controls
              </div>
            </li>
            <li className={styles.ListItem}>
              <FinancialReturnIcon className={styles.Icon} />
              <div className={styles.ListItemContent}>Financial returns</div>
            </li>
            <li className={styles.ListItem}>
              <HealthIcon className={styles.Icon} />
              <div className={styles.ListItemContent}>
                Health and environmental benefits
              </div>
            </li>
          </ul>
        </div>
      </div>
      {metrics.map(({ label, unit, value }, i) => (
        <div className={styles.ReportSection_InfoBlock} key={i}>
          <div className={styles.ReportSection_InfoBlock_label}>{label}</div>
          <div className={styles.ReportSection_InfoBlock_blockcontent}>
            <div className={styles.ReportSection_InfoBlock_value}>
              {value}
              {value === "-10\u20130%" && (
                <TooltipInfoIcon message={ErrorMessage} />
              )}
            </div>
            <div className={styles.ReportSection_InfoBlock_unit}>{unit}</div>
          </div>
        </div>
      ))}
    </div>
    <CTAButton>Learn More</CTAButton>
  </div>
)

ReportSection.propTypes = {
  metrics: PropTypes.arrayOf(MetricType),
}

ReportSection.defaultProps = {
  metrics: [],
}

export default ReportSection
