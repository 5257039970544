import React from "react"
import PropTypes from "prop-types"
import styles from "./ReportPage.module.scss"

import ReportHeaderSection from "../ReportHeaderSection"
import MastheadSection from "../MastheadSection"
import ReportSection from "../ReportSection"
import ImplementationOptionsSection from "../ImplementationOptionsSection"
import TestimonialsSection from "../TestimonialsSection"
import FAQSection from "../FAQSection"

import MetricType from "../../constants/proptypes/Metric"

const ReportPagePresenter = ({ address, metrics, reportInfo }) => (
  <article className={styles.ReportPage} data-testid="ReportPage">
    <ReportHeaderSection />
    <MastheadSection address={address} />
    <ReportSection metrics={metrics} />
    <ImplementationOptionsSection {...reportInfo} />
    <FAQSection />
    <TestimonialsSection />
  </article>
)

ReportPagePresenter.propTypes = {
  address: PropTypes.string.isRequired,
  metrics: PropTypes.arrayOf(MetricType),
  reportInfo: PropTypes.object,
}

ReportPagePresenter.defaultProps = {
  metrics: [],
}

export default ReportPagePresenter
