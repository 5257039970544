import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import styles from "./RequalificationPage.module.scss"
import RequalificationForm from "components/RequalificationForm"
import { useDispatch, useSelector } from "react-redux"
import { fetchReportInfo, submitRequalifyInfo } from "redux/actions"
import { useParams, useNavigate } from "react-router-dom"
import LoadingScreen from "components/LoadingScreen"
import FullBleedHeader from "components/FullBleedHeader"

const RequalificationPage = () => {
  const { buildingId } = useParams()
  const dispatch = useDispatch()
  useEffect(() => dispatch(fetchReportInfo(buildingId)), [buildingId])
  const [hasSubmitted, setHasSubmitted] = useState(false)
  let navigate = useNavigate()
  const onSubmit = useCallback(
    data => {
      setHasSubmitted(true)
      dispatch(
        submitRequalifyInfo(buildingId, {
          is_qualified_lead: !data["none_of_the_above"],
          requalification_response: Object.keys(data).filter(i => data[i]),
        })
      )
    },
    [dispatch, buildingId]
  )

  const {
    status: reqaulifyStatus,
    data: reqaulifyInfo,
    isError: isReqaulifyError,
  } = useSelector(state => state.requalifyInfo)

  useEffect(() => {
    if (!hasSubmitted) {
      return
    }
    if (isReqaulifyError) {
      window.alert(message)
    } else if (reqaulifyStatus == "succeeded") {
      if (reqaulifyInfo.is_qualified_lead) {
        reportInfo?.no_of_units > 10
          ? goToLargeBuilding()
          : goToReport(buildingId)
      } else {
        goToThankYou()
      }
    }
  }, [hasSubmitted, reqaulifyInfo])

  const {
    status,
    data: reportInfo,
    isError,
  } = useSelector(state => state.reportInfo)
  if (status === "loading" || status === "idle") {
    ;<LoadingScreen />
  }

  const goToLargeBuilding = () => {
    navigate("../../largebuilding/", { replace: true })
  }
  const goToReport = id => {
    navigate("../../report/" + id, { replace: true })
  }
  const goToThankYou = () => {
    navigate("../../subscriber", { replace: true })
  }

  return (
    <>
      <FullBleedHeader />
      <div
        className={styles.RequalificationPage}
        data-testid="RequalificationPage"
      >
        <RequalificationForm onSubmit={onSubmit} buildingData={reportInfo} />
      </div>
    </>
  )
}

RequalificationPage.propTypes = {}

RequalificationPage.defaultProps = {}

export default RequalificationPage
