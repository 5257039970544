import React from "react"
import PropTypes from "prop-types"
import Select from "components/Select"
import Checkbox from "components/Checkbox"

const BuildingInfoSubForm = ({ register, errors, styles, watch }) => {
  const building_type = watch("building_type")

  function NumberofUnits() {
    return (
      <>
        <div className={styles.InputGroup}>
          <label htmlFor={"no_of_units"}>Number of Units</label>
          <div className={(styles.InputGroupAddon, styles.Suffix)}>
            <input
              className={styles.SqftInput}
              id={"no_of_units"}
              type="number"
              {...register("no_of_units", {
                required: true,
                validate: {
                  number: v =>
                    parseInt(v) > 0 ||
                    "Please enter just the number for Number of Units",
                },
              })}
            />
            <span className={styles.InputGroupAddon}> Units </span>
            {errors.no_of_units && (
              <div className={styles.Error}>{errors.no_of_units.message}</div>
            )}
          </div>
        </div>
        {errors?.no_of_units?.type === "required" && (
          <span className={styles.isRequired}>
            Number of units is required.
          </span>
        )}
      </>
    )
  }

  return (
    <fieldset data-testid="BuildingInfoSubForm">
      {/* Building Type */}
      <Select
        name="building_type"
        label="Building Type"
        options={{
          SINGLE_FAM: "Single Family",
          SMALL_RES: "Small Residential (2-4 Units)",
          MULTI_FAM: "Multifamily Residential (5+ Units)",
          CO_OP: "Co-Op",
          MIXED_USE: "Mixed Use (Commercial / Residential)",
          HOW: "House of Worship / Religious Institution",
          OTHER: "Other",
        }}
        errors={errors.building_type}
        register={register}
        isRequired
        styles={styles}
      />
      {errors?.building_type?.type === "required" && (
        <span className={styles.isRequired}> Building type is required. </span>
      )}
      {["MULTI_FAM", "CO_OP", "MIXED_USE"].includes(building_type) && (
        <NumberofUnits />
      )}
      {/* Building Age  */}
      <Checkbox
        name="is_old_building"
        label="My building is more than 15 years old"
        errors={errors.is_old_building}
        register={register}
        styles={styles}
      />
      {/* Square Footage  */}
      <div className={styles.InputGroup}>
        <label htmlFor={"square_footage"}>Square Footage</label>
        <div className={(styles.InputGroupAddon, styles.Suffix)}>
          <input
            className={styles.SqftInput}
            id={"square_footage"}
            {...register("square_footage", {
              required: true,
              valueAsNumber: true,
              validate: {
                number: v =>
                  parseInt(v) > 0 ||
                  "Please enter just the number for Square Footage",
              },
            })}
          />
          <span className={styles.InputGroupAddon}> Sqft </span>
          {errors.square_footage && (
            <div className={styles.Error}>{errors.square_footage.message}</div>
          )}
        </div>
      </div>
      {errors?.square_footage?.type === "required" && (
        <span className={styles.isRequired}> Square Footage is required. </span>
      )}
    </fieldset>
  )
}

BuildingInfoSubForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  styles: PropTypes.object,
  watch: PropTypes.func,
}

BuildingInfoSubForm.defaultProps = {
  errors: {},
  styles: {},
}

export default BuildingInfoSubForm
