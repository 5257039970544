import React from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import "./css/App.css"
import ReactGA from "react-ga"
import TagManager from "react-gtm-module"
import ReportPage from "./components/ReportPage"
import StartPage from "./components/StartPage"
import BuildingInfoPage from "./components/BuildingInfoPage"
import RequalificationPage from "./components/RequalificationPage"
import LargeBuildingPage from "./components/LargeBuildingPage"
import Subscriber from "./components/Subscriber"
import Waitlist from "./components/Waitlist"
import { Helmet } from "react-helmet"

const App = () => {
  const tagManagerArgs = {
    gtmId: "GTM-NR477HS",
  }
  if (process.env.NODE_ENV == "production") {
    TagManager.initialize(tagManagerArgs)
    ReactGA.initialize("UA-67611405-11")
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
  const hubspotKey = process.env.REACT_APP_HUBSPOT_KEY
  return (
    <>
      {hubspotKey && (
        <Helmet>
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js.hs-scripts.com/20299415.js"
          ></script>
        </Helmet>
      )}
      <div className="App">
        <Router>
          <Routes>
            <Route index element={<Navigate to="start" />} />
            <Route path="start" element={<StartPage />} />
            <Route path="verify">
              <Route path=":buildingId" element={<BuildingInfoPage />} />
            </Route>
            <Route path="requalify">
              <Route path=":buildingId" element={<RequalificationPage />} />
            </Route>
            <Route path="subscriber" element={<Subscriber />} />
            <Route path="waitlist" element={<Waitlist />} />
            <Route path="largebuilding" element={<LargeBuildingPage />} />
            <Route path="report">
              <Route path=":buildingId" element={<ReportPage />} />
            </Route>
          </Routes>
        </Router>
      </div>
    </>
  )
}

App.propTypes = {}

App.defaultProps = {}

export default App
