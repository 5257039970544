import React from "react"
import PropTypes from "prop-types"
import styles from "./DoItYourself.module.scss"

import ImplementationOption from "../ImplementationOption"
import DIYSteps from "../DIYSteps"
import LabeledMetric from "../LabeledMetric"

const DoItYourself = buildingInfo => (
  <ImplementationOption
    className={styles.DoItYourself}
    data-testid="DoItYourself"
    title="Do It Yourself"
  >
    <DIYSteps />
    <LabeledMetric
      comp={"DIY"}
      metric={{
        label: "",
        value: `${buildingInfo.diy_month_min}\u2013${buildingInfo.diy_month_max}`,
        unit: "Months of your time and significant upfront cost",
      }}
    />
  </ImplementationOption>
)

DoItYourself.propTypes = {}

DoItYourself.defaultProps = {}

export default DoItYourself
