import React from "react"
import PropTypes from "prop-types"
import styles from "./BuildingForm.module.scss"

import { useForm } from "react-hook-form"

import BuildingInfoSubForm from "components/BuildingInfoSubForm"
import HvacInfoSubForm from "components/HvacInfoSubForm"
import EmailSubForm from "components/EmailSubForm"
import Button from "components/Button"
import FullBleedHeader from "components/FullBleedHeader"

const BuildingForm = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ shouldUnregister: true })

  return (
    <div className={styles.BuildingForm}>
      <div className={styles.FormHeading}> Your Building Information </div>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="BuildingForm">
        <BuildingInfoSubForm
          register={register}
          errors={errors}
          styles={styles}
          watch={watch}
        />
        <HvacInfoSubForm
          register={register}
          errors={errors}
          styles={styles}
          watch={watch}
        />
        <EmailSubForm register={register} errors={errors} styles={styles} />
        <div className={styles.ButtonSection}>
          <Button> Next </Button>
        </div>
      </form>
    </div>
  )
}

BuildingForm.propTypes = {
  userSubmittedBuildingId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}

BuildingForm.defaultProps = {}

export default BuildingForm
