import React from "react"
import PropTypes from "prop-types"
import { useForm, Controller } from "react-hook-form"
import styles from "./AddressCollectionWidget.module.scss"
import Button from "components/Button"
import MapboxAutocomplete from "react-mapbox-autocomplete"

const AddressCollectionWidget = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    control,
  } = useForm()

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.AddressCollectionWidget}
      data-testid="AddressCollectionWidget"
    >
      <Controller
        render={() => (
          <MapboxAutocomplete
            publicKey={process.env.REACT_APP_MAPBOX_TOKEN}
            placeholder="Enter Building Address"
            inputClass="form-control search"
            onSuggestionSelect={result => {
              if (result.split(",").length < 4) {
                setError("address", {
                  type: "custom",
                  message: "Please select a proper street address",
                })
              } else {
                setValue("address", result)
              }
            }}
            country="us"
            resetSearch={false}
          />
        )}
        name="address"
        control={control}
        errors={errors.address}
        rules={{ required: "Please select the address." }}
      />
      {errors.address && (
        <div className={styles.Error}>{errors.address.message}</div>
      )}
      <div className={styles.NextButtonSection}>
        <Button> Next </Button>
      </div>
    </form>
  )
}

AddressCollectionWidget.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

AddressCollectionWidget.defaultProps = {}

export default AddressCollectionWidget
