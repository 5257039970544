import React from "react"
import PropTypes from "prop-types"
import styles from "./HireBlocPower.module.scss"

import ImplementationOption from "../ImplementationOption"
import HireSteps from "../HireSteps"
import LabeledMetric from "../LabeledMetric"
import NoHeadaches from "../NoHeadaches"

const HireBlocPower = buildingInfo => (
  <ImplementationOption
    className={styles.HireBlocPower}
    data-testid="HireBlocPower"
    title="Hire BlocPower"
  >
    <HireSteps />
    <LabeledMetric
      comp={"HireBP"}
      metric={{
        label: "Estimated costs",
        value: `$${buildingInfo.blocpower_cost_monthly_min}\u2013$${buildingInfo.blocpower_cost_monthly_max}`,
        unit: "monthly payments",
      }}
    />
    <div className={styles.HrSection}>
      <hr />
    </div>
    <NoHeadaches />
  </ImplementationOption>
)

HireBlocPower.propTypes = {}

HireBlocPower.defaultProps = {}

export default HireBlocPower
