import React from "react"
import PropTypes from "prop-types"
import styles from "./MastheadSection.module.scss"

const MastheadSection = ({ address }) => (
  <div className={styles.MastheadSection} data-testid="MastheadSection">
    <div className={styles.Address}>
      <h1 className={styles.Heading}>Building Heating & Cooling Report</h1>
      <address className={styles.AddressText}>{address}</address>
    </div>
  </div>
)

MastheadSection.propTypes = {
  address: PropTypes.string.isRequired,
}

MastheadSection.defaultProps = {}

export default MastheadSection
