import React from "react"
import PropTypes from "prop-types"
import styles from "./Icons.module.scss"

import classNames from "classnames"

import ElectricitySrc from "images/icons/electricity.svg"
import FinancialReturnSrc from "images/icons/financial-return.svg"
import HealthSrc from "images/icons/health.svg"
import LedLightSrc from "images/icons/led-light.svg"
import RoomsSrc from "images/icons/rooms.svg"
import SeasonsSrc from "images/icons/seasons.svg"
import SolarSrc from "images/icons/solar.svg"
import WindowsSrc from "images/icons/windows.svg"

export const ElectricityIcon = ({ className }) => (
  <img
    alt={"Electricity icon"}
    src={ElectricitySrc}
    className={classNames(styles.Icon, className)}
  />
)

ElectricityIcon.propTypes = {
  className: PropTypes.string,
}

ElectricityIcon.defaultProps = {}

export const FinancialReturnIcon = ({ className }) => (
  <img
    alt={"FinancialReturn icon"}
    src={FinancialReturnSrc}
    className={classNames(styles.Icon, className)}
  />
)

FinancialReturnIcon.propTypes = {
  className: PropTypes.string,
}

FinancialReturnIcon.defaultProps = {}

export const HealthIcon = ({ className }) => (
  <img
    alt={"Health icon"}
    src={HealthSrc}
    className={classNames(styles.Icon, className)}
  />
)

HealthIcon.propTypes = {
  className: PropTypes.string,
}

HealthIcon.defaultProps = {}

export const LedLightIcon = ({ className }) => (
  <img
    alt={"LedLight icon"}
    src={LedLightSrc}
    className={classNames(styles.Icon, className)}
  />
)

LedLightIcon.propTypes = {
  className: PropTypes.string,
}

LedLightIcon.defaultProps = {}

export const RoomsIcon = ({ className }) => (
  <img
    alt={"Rooms icon"}
    src={RoomsSrc}
    className={classNames(styles.Icon, className)}
  />
)

RoomsIcon.propTypes = {
  className: PropTypes.string,
}

RoomsIcon.defaultProps = {}

export const SeasonsIcon = ({ className }) => (
  <img
    alt={"Seasons icon"}
    src={SeasonsSrc}
    className={classNames(styles.Icon, className)}
  />
)

SeasonsIcon.propTypes = {
  className: PropTypes.string,
}

SeasonsIcon.defaultProps = {}

export const SolarIcon = ({ className }) => (
  <img
    alt={"Solar icon"}
    src={SolarSrc}
    className={classNames(styles.Icon, className)}
  />
)

SolarIcon.propTypes = {
  className: PropTypes.string,
}

SolarIcon.defaultProps = {}

export const WindowsIcon = ({ className }) => (
  <img
    alt={"Windows icon"}
    src={WindowsSrc}
    className={classNames(styles.Icon, className)}
  />
)

WindowsIcon.propTypes = {
  className: PropTypes.string,
}

WindowsIcon.defaultProps = {}
