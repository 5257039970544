import blocLink from "utils/js/blocLink"

// asynchronous action creator
export const submitBuildingInfo = (id, data) => dispatch => {
  dispatch({ type: "SubmitBuildingInfoRequested" })
  return blocLink
    .put(`building/${id}`, JSON.stringify(data))
    .then(({ data }) => dispatch({ type: "SubmitBuildingInfoSucceeded", data }))
    .catch(({ message, response }) => {
      dispatch({
        type: "SubmitBuildingInfoFailed",
        data: response?.data || { message },
      })
    })
}

export const submitRequalifyInfo = (id, data) => dispatch => {
  dispatch({ type: "RequalifyInfoRequested" })
  return blocLink
    .put(`building/${id}`, JSON.stringify(data))
    .then(({ data }) => dispatch({ type: "RequalifyInfoSucceeded", data }))
    .catch(({ message, response }) => {
      dispatch({
        type: "RequalifyInfoFailed",
        data: response?.data || { message },
      })
    })
}

export const fetchReportInfo = id => dispatch => {
  dispatch({ type: "ReportInfoRequested" })
  return blocLink
    .get(`building/${id}`)
    .then(({ data }) => dispatch({ type: "ReportInfoSucceeded", data }))
    .catch(err => {
      dispatch({
        type: "ReportInfoFailed",
        data: response?.data || { message },
      })
    })
}

export const submitAddress = data => {
  return dispatch => {
    dispatch({ type: "AddressSubmissionRequested" })
    return blocLink
      .post(`building/`, JSON.stringify(data))
      .then(res => dispatch({ type: "AddressSubmissionSucceeded", data: res }))
      .catch(err => {
        const errorResponse = err.response ? err.response : { status: 502 }
        dispatch({
          type: "AddressSubmissionFailed",
          data: errorResponse.data,
        })
      })
  }
}
