import React from "react"
import PropTypes from "prop-types"
import styles from "./HireSteps.module.scss"

const HireSteps = () => (
  <div className={styles.HireSteps} data-testid="HireSteps">
    <ul className={styles.CheckBoxBullet}>
      <li>
        <strong>Hire BlocPower</strong>
        <ul>
          <li>One point of contact to manage the entire process</li>
          <li>No money down financing with low monthly payment</li>
          <li>Ongoing maintenance included</li>
          <li>Experts ensure accurate project scope and budget</li>
          <li>
            Experts maximize your incentives to take full advantage of public
            funding
          </li>
          <li>
            Price Certainty - our monthly price includes everything<sup>*</sup>
            &nbsp;and is locked in
          </li>
        </ul>
      </li>
    </ul>
    <div className={styles.AsteriskText}>
      <sup>*</sup> Not including changes in the project scope
    </div>
  </div>
)

HireSteps.propTypes = {}

HireSteps.defaultProps = {}

export default HireSteps
