import React from "react"
import PropTypes from "prop-types"
import styles from "./Checkbox.module.scss"

const Checkbox = ({
  name,
  label,
  errors,
  isChecked,
  register,
  isRequired: required,
  styles,
}) => (
  <div className={styles.InputGroup} data-testid="Checkbox">
    <label htmlFor={name}>{label}</label>
    <input
      id={name}
      {...register(name, { required })}
      type="checkbox"
      defaultChecked={isChecked}
    />
    {errors && <div className={styles.Errors}>{errors.message}</div>}
  </div>
)

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  isRequired: PropTypes.bool,
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
  styles: PropTypes.object,
}

Checkbox.defaultProps = {
  isChecked: false,
  isRequired: false,
  errors: {},
  styles: {},
}

export default Checkbox
