import Axios from "axios"

export function getHeaders() {
  return {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Token ${process.env.REACT_APP_BLOCLINK_TOKEN}`,
  }
}

const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_BLOCLINK_URL}/api/v1/`,
  timeout: 2000,
  headers: getHeaders(),
})

export default axios
