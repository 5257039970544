import React from "react"
import PropTypes from "prop-types"
import styles from "./FAQSection.module.scss"
import Faq from "react-faq-component"

const data = {
  rows: [
    {
      title: <p> How does all-electric heat and hot water work? </p>,
      content: (
        <>
          <p>
            Heating, cooling and hot water system equipment that is powered by
            electricity — not the combustion of fossil fuels — use ambient air
            from outside the building or the ground as either a heat source or
            heat sink. Check out this{" "}
            <a href="https://www.blocpower.io/posts/what-is-an-air-source-heat-pump">
              resource guide
            </a>{" "}
            for a deeper look, or read on for a high-level overview.
          </p>
          <p>
            This technology, called a heat pump, gets its name because it moves
            heat into a building — it doesn’t generate it as furnaces and
            boilers do. Heat pumps also function in reverse, acting as an air
            conditioner. In heating mode, the ambient air from outside the
            building acts as the heat source. Modern technology makes this
            possible even when the ambient air is cold; the heat pump’s
            refrigerant can extract heat even in cold climates.
          </p>
          <p>
            Like any heating and cooling system, electricity-powered equipment
            are at their best in a home or building that maximizes efficiency,
            which is why BlocPower offers these systems in conjunction with
            other efficiency measures as a part of the Climate Comfort Service.
          </p>
        </>
      ),
    },
    {
      title: <p> How much does the BlocPower Climate Comfort Service cost? </p>,
      content: (
        <>
          <p>
            At BlocPower, we know that every home and building is unique, as are
            their owners. That’s why our Climate Comfort Service can encompass
            as much or as little of your home or building as you like. For those
            reasons, project and retrofit costs can vary.
          </p>
          <p>
            Whatever you choose, we offer no-money-down leases with low, fixed
            monthly payments that can often be paid back by the savings obtained
            from moving away from inefficient, dirty fossil fuel-reliant
            equipment. Many customers often see savings between 20-70%! Learn
            more about our innovative financing tools that never require a lien
            on your property{" "}
            <a href="https://www.blocpower.io/posts/energy-service-agreements">
              here
            </a>
            {". "}
            Read more about how heat pumps can save you money{" "}
            <a href="https://www.blocpower.io/posts/do-heat-pumps-save-money">
              here
            </a>
            {"."}
          </p>
        </>
      ),
    },
    {
      title: <p> How long does it take to install the new system? </p>,
      content: (
        <p>
          Installation times can vary based on complexity of project, age of
          building, type of system and several other factors. In a multifamily
          building, it takes an average of 3 days per unit to install a new
          system.
        </p>
      ),
    },
    {
      title: <p> How does the BlocPower Process work? </p>,
      content: (
        <>
          <p>
            Our team walks with you through the whole process of scoping,
            designing and implementing your new system.
          </p>
          <ol>
            <li>Free Estimate with an Instant Building Report</li>
            <li>Provide Lease Quoted Statement of Work</li>
            <li>Sign Agreement</li>
            <li>Finalize Project</li>
            <li>Installation</li>
            <li>Ongoing Maintenance with BlocPower’s Customer Success Team</li>
          </ol>
          <p>
            Learn more about our process{" "}
            <a href="https://www.blocpower.io/#the-process"> here </a>
            {"."}
          </p>
        </>
      ),
    },
  ],
}

const FAQSection = () => (
  <div className={styles.FAQSection} data-testid="FAQSection">
    <div className={styles.FAQTitle}> Frequently Asked Questions </div>
    <div className={styles.FAQContent}>
      <Faq data={data} />
    </div>
  </div>
)

FAQSection.propTypes = {}

FAQSection.defaultProps = {}

export default FAQSection
