import React from "react"
import PropTypes from "prop-types"
import styles from "./NoHeadaches.module.scss"

const NoHeadaches = () => (
  <div>
    <div className={styles.NoHeadaches} data-testid="NoHeadaches">
      <div className={styles.Heading}>no headaches!</div>
      <div className={styles.SubHeading}>
        BlocPower takes care of the whole process
      </div>
    </div>
  </div>
)

NoHeadaches.propTypes = {}

NoHeadaches.defaultProps = {}

export default NoHeadaches
