import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { fetchReportInfo } from "redux/actions"
import { useParams } from "react-router-dom"
import ReactGA from "react-ga"
import ReportPagePresenter from "./ReportPagePresenter"
import ReportLoadingScreen from "components/ReportLoadingScreen"
// This dispatches fetchReportInfo and formats the data into Metrics
const ReportPageController = () => {
  const { buildingId } = useParams()
  const dispatch = useDispatch()
  const [showLoading, setShowLoading] = useState(true)
  useEffect(() => dispatch(fetchReportInfo(buildingId)), [buildingId])

  useEffect(() => {
    setTimeout(() => setShowLoading(false), 3000)
  }, [])

  const {
    status,
    data: reportInfo,
    isError,
  } = useSelector(state => state.reportInfo)

  if (status === "loading" || status === "idle" || showLoading) {
    return <ReportLoadingScreen />
  }

  if (isError) {
    return JSON.stringify(reportInfo.message)
  }
  const metrics = []

  metrics.push({
    label: "Estimated Savings",
    value:
      reportInfo.savings_min === 0 && reportInfo.savings_max === 0
        ? "Not Applicable"
        : `${reportInfo.savings_min}\u2013${reportInfo.savings_max}%`,
    unit: "decrease on monthly energy bill",
  })

  metrics.push({
    label: "Estimated Incentives",
    value: `$${reportInfo.incentives_min}\u2013$${reportInfo.incentives_max}`,
    unit: "potential rebates from state, local, federal incentives",
  })

  if (process.env.NODE_ENV == "production") {
    ReactGA.pageview("/bldthankyou/1/" + reportInfo.building_type)
  }

  return (
    <ReportPagePresenter
      address={reportInfo.address}
      metrics={metrics}
      reportInfo={reportInfo}
    />
  )
}

export default ReportPageController
