import React from "react"
import PropTypes from "prop-types"
import styles from "./ImplementationOptionsSection.module.scss"

import HireBlocPower from "components/HireBlocPower"
import DoItYourself from "components/DoItYourself"
import CTAButton from "components/CTAButton"

const ImplementationOptionsSection = reportInfo => (
  <div
    className={styles.ImplementationOptionsSection}
    data-testid="ImplementationOptionsSection"
  >
    <div className={styles.Title}>Implementation Options</div>
    <div className={styles.FloatingCells}>
      <DoItYourself {...reportInfo} />
      <HireBlocPower {...reportInfo} />
    </div>
    <div className={styles.GetStarted}>
      <CTAButton>Get Started</CTAButton>
    </div>
  </div>
)

ImplementationOptionsSection.propTypes = {}

ImplementationOptionsSection.defaultProps = {}

export default ImplementationOptionsSection
